<template>
  <section>
    <navi-bar>
      {{$t('front.mypage.memInfoModify')}}
    </navi-bar>
  </section>
  <transition name="fade">
    <info-in v-if="showAuth" @close="onPasswordCheck" />
  </transition>

  <p class="myinfoname"><span class="">{{userData.memName}}</span>{{$t('front.mypage.infoEdit')}}<br>{{$t('front.mypage.security')}}</p>
  <div class="moneyinfowrap">
    <ul class="appliinput">
      <li>{{$t('front.common.memId')}}</li>
      <li>{{userData.memId}}</li>
    </ul>
    <ul class="appliinput">
      <li>{{$t('front.mypage.passwordNew')}}</li>
      <li><input type="password" class="numb" v-model="model.memPass"></li>
    </ul>
    <p class="myinfoex">{{$t('front.signup.passwordPlaceholder')}}</p>
    <ul class="appliinput">
      <li>{{$t('front.mypage.passwordOkNew')}}</li>
      <li><input type="password" class="numb" v-model="model.memPassCheck"></li>
    </ul>
    <ul class="appliinput">
      <li>{{$t('front.common.cashOutPassNew')}}</li>
      <li><input type="text" id="cashOutPass"  maxlength="4" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"   pattern="[0-9]*" class="numb" v-model="model.cashOutPass" inputmode="numeric"/></li>
    </ul>
    <p class="myinfoex">{{$t('front.signup.cashOutPassPlaceholder')}}</p>
    <ul class="appliinput mb20">
      <li>{{$t('front.mypage.ExchangepasswordOk')}}</li>
      <li><input type="text" id="cashOutPass"  maxlength="4"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  pattern="[0-9]*" class="numb" v-model="model.cashOutPassCheck" inputmode="numeric"/></li>
    </ul>
    <!--ul class="appliinput">
      <li>레벨</li>
      <li>레벨 LV.{{userData.memLevel}} (점수: 0)</li>
    </ul-->
    <!--    <ul class="appliinput">-->
    <!--      <li>스포츠</li>-->
    <!--      <li>Bronze</li>-->
    <!--    </ul>-->
    <!--ul class="appliinput">
      <li>카지노</li>
      <li>레벨 LV. {{userData.casinoLevel}}</li>
    </ul>
    <ul class="appliinput">
      <li>슬롯</li>
      <li>레벨 LV. {{userData.slotLevel}}</li>
    </ul-->
    <!--    <ul class="appliinput">-->
    <!--      <li>미니게임</li>-->
    <!--      <li>Bronze</li>-->
    <!--    </ul>-->
  </div>
  <div class="btnwrap mb100">
    <a class="ok" @click="onUpdate">{{$t('front.board.edit')}}</a>
  </div>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import InfoIn from '@/components/common/mobile/infoin'
import MyPageInfo from '@/views/member/mypage/info'

export default {
  name: 'info.vue',
  components: {
    NaviBar,
    InfoIn
  },
  mixins: [
    MyPageInfo
  ],
  methods: {
    onPasswordCheck (value) {
      if (value === 'pass') {
        this.showAuth = false
      } else {
        this.goBack()
      }
    }
  }
}
</script>
<style scoped src="@/styles_mobile/money.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped>
#cashOutPass, #cashOutPassCheck {
  -webkit-text-security: disc;
}

.moneyinfowrap {position: relative !important;top: 0;color: #000;}
.appliinput li {position: relative;border-bottom: 1px solid #333;}
.appliinput li:first-child {font-weight: bold;font-size: 15px; padding-right: 5px;width: 75%;}
.appliinput li:first-child::before {content: '';display: block;width: 2px;height: 15px;background: #333;position: absolute;right: 0;}
.appliinput li+li {padding-left: 10px;}
.numb {background: none;color: #000;}
.name {display: inline;}
</style>
